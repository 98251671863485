<template>
  <div id="defaultId1">
    <div class="pb20">
      <div class="flex_center_between_box">
        <!-- <div c> -->

        <div class="left_menu" :class="$store.state.app.isMenu ? '' : 'hidemenu'">
          <div class="table">
            <div class="left_menu_context">
              <p class="left_menu_context-title">Date range</p>
  <div class="dadePickerBorder flex_between_box">
    <el-date-picker
              style="width: 45%;" 
      v-model="datePickerOne"
      prefix-icon="''"
      class="datePickerOne"
      value-format="yyyy"
      type="year"
        @change="datepcikerChange"
      placeholder="Start date">
    </el-date-picker>
    <el-date-picker
       class="datePickerOne"
              style="width: 50%;" 
      v-model="datePickerTwo"
      type="year"
      @change="datepcikerChange"
      value-format="yyyy"
      placeholder="End date">
    </el-date-picker>
  </div>
              <!-- <el-date-picker v-model="datePicker" style="width: 100%;" type="monthrange" range-separator=""
                start-placeholder="Start date" value-format="yyyy-MM-dd HH:mm:ss"
                :default-time="['00:00:00', '23:59:59']" end-placeholder="End date"></el-date-picker> -->
            </div>
            <div class="left_menu_context">
              <p class="left_menu_context-title">Import & Export</p>
              <el-radio-group v-model="Improtradio" @input="Improtradiochange">
                <el-radio class="margin-bottom20" :label="0">Import & Export</el-radio>
                <el-radio class="margin-bottom20" :label="1">Import</el-radio>
                <el-radio :label="2">Export</el-radio>
              </el-radio-group>
            </div>
            <div class="left_menu_context">
              <p class="left_menu_context-title">Products</p>
              <div class="margin-bottom20">
                <el-select filterable v-model="hsCodevalue" clearable style="width: 100%;" placeholder="Enter HS Code" @change="selectChange">
                  <el-option v-for="item in hsCodeList" :key="item.value" :label="item.value" :value="item.value">
                  </el-option>
                </el-select>

              </div>

              <el-select filterable v-model="hsCodevalue" clearable style="width: 100%;" placeholder="Enter  Products"  @change="selectChange">
                <el-option v-for="item in productList" :key="item.value" :label="item.name" :value="item.value">
                </el-option>
              </el-select>
            </div>
            <div class="left_menu_context">
              <p class="left_menu_context-title">Buyer Names</p>

              <el-select filterable v-model="buyerNamevalue" clearable :disabled="Improtradio == 1||Improtradio == 0" style="width: 100%;" @change="selectChange"
                placeholder="Enter Buyer Names">
                <el-option v-for="item in buyerNameList" :key="item.value" :label="item.name" :value="item.value">
                </el-option>
              </el-select>
            </div>
            <div class="left_menu_context">
              <p class="left_menu_context-title">Supplier Names</p>

              <el-select filterable v-model="supplierNamevalue" clearable :disabled="Improtradio == 2||Improtradio == 0" style="width: 100%;"  @change="selectChange"
                placeholder="Enter Supplier Names">
                <el-option v-for="item in supplierNameList" :key="item.value" :label="item.name" :value="item.value">
                </el-option>
              </el-select>
            </div>
            <div class="left_menu_context">
              <p class="left_menu_context-title">Country of Destination</p>

              <el-select filterable v-model="countryOfDesvalue" clearable :disabled="Improtradio == 1" style="width: 100%;"  @change="selectChange"
                placeholder="Select Country">
                <el-option v-for="item in countryOfDesList" :key="item.value" :label="item.name" :value="item.value">
                </el-option>
              </el-select>
            </div>
            <div class="left_menu_context">
              <p class="left_menu_context-title">Country of Origin</p>

              <el-select filterable v-model="countryOfOrgvalue" clearable :disabled="Improtradio == 2" style="width: 100%;" @change="selectChange"
                placeholder="Select Country">
                <el-option v-for="item in countryOfOrgList" :key="item.value" :label="item.name" :value="item.value">
                </el-option>
              </el-select>
            </div>
          </div>
        </div>
        <div id="cc5f49" :class="$store.state.app.isMenu
          ? 'scrollbar udb507'
          : ' scrollbar udb507 udb507two'
          ">
          <div class="right_cont_box  company_box">
            <div class="table-index">
              <h2 class="operations-head flex_center_between_box">
                <span class="head-titel detail_title_20">Product Shipping List
                </span>

              </h2>
              <div class=" operations-box-bottom table_X" >
                <el-table :data="ImporttableData" style="width: 100%"   height="850" v-loading="loading" :key="key" empty-text="No Data">
                  <el-table-column prop="ie_date" width="100" label="Date" show-overflow-tooltip align="center">
                    <template slot-scope="scope">
                      <span class="table-content">{{ scope.row.ie_date }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column prop="hscode" label="Hscode" align="center" width="100" show-overflow-tooltip>

                    <template slot-scope="scope">
                      <span class="table-content">{{ scope.row.hscode }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column prop="hscode_description_inter" label="Product" show-overflow-tooltip>
                    <template slot-scope="scope">
                      <span class="table-content company_link">{{ scope.row.hscode_description_inter }}</span>
                    </template>
                  </el-table-column>


                  <el-table-column prop="import_name" label="Buyer Name" show-overflow-tooltip>
                    <template slot-scope="scope">
                      <span class="table-content company_link">{{ scope.row.import_name
                      }}<svg t="1740639217247" class="icon SactionIcon" v-if="scope.row.import_saction"
                          viewBox="0 0 1293 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="6133"
                          width="1.4285rem" height="1.4285rem">
                          <path
                            d="M743.316211 54.649263l487.154526 797.157053A113.178947 113.178947 0 0 1 1133.891368 1024H159.528421A113.178947 113.178947 0 0 1 63.056842 851.806316l487.208421-797.103158A113.178947 113.178947 0 0 1 737.28 45.810526l6.036211 8.838737z m395.15621 853.369263l-487.154526-797.103158-0.754527-1.077894-1.024-0.754527a5.389474 5.389474 0 0 0-7.383579 1.778527l-487.154526 797.103158a5.389474 5.389474 0 0 0 4.581053 8.245894H1133.945263a5.389474 5.389474 0 0 0 4.581053-8.192z"
                            fill="#FF6A6A" p-id="6134"></path>
                          <path
                            d="M646.736842 323.368421a75.290947 75.290947 0 0 1 75.075369 80.626526l-17.354106 242.903579a57.882947 57.882947 0 0 1-115.442526 0l-17.354105-242.903579A75.290947 75.290947 0 0 1 646.736842 323.368421z"
                            fill="#FF6A6A" p-id="6135"></path>
                          <path
                            d="M646.736842 808.421053m-53.894737 0a53.894737 53.894737 0 1 0 107.789474 0 53.894737 53.894737 0 1 0-107.789474 0Z"
                            fill="#FF6A6A" p-id="6136"></path>
                        </svg></span>

                    </template>
                  </el-table-column>
                  <el-table-column prop="import_country_inter" label="Destination" width="160" show-overflow-tooltip>
                    <template slot-scope="scope">
                      <span class="table-content company_link">{{ scope.row.import_country_inter }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column prop="export_name" label="Supplier Name" show-overflow-tooltip>
                    <template slot-scope="scope">
                      <span class="table-content company_link" @click="setid(scope.row, 'Supplier')">{{
                        scope.row.export_name
                      }}<svg t="1740639217247" class="icon SactionIcon" v-if="scope.row.export_saction"
                          viewBox="0 0 1293 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="6133"
                          width="1.4285rem" height="1.4285rem">
                          <path
                            d="M743.316211 54.649263l487.154526 797.157053A113.178947 113.178947 0 0 1 1133.891368 1024H159.528421A113.178947 113.178947 0 0 1 63.056842 851.806316l487.208421-797.103158A113.178947 113.178947 0 0 1 737.28 45.810526l6.036211 8.838737z m395.15621 853.369263l-487.154526-797.103158-0.754527-1.077894-1.024-0.754527a5.389474 5.389474 0 0 0-7.383579 1.778527l-487.154526 797.103158a5.389474 5.389474 0 0 0 4.581053 8.245894H1133.945263a5.389474 5.389474 0 0 0 4.581053-8.192z"
                            fill="#FF6A6A" p-id="6134"></path>
                          <path
                            d="M646.736842 323.368421a75.290947 75.290947 0 0 1 75.075369 80.626526l-17.354106 242.903579a57.882947 57.882947 0 0 1-115.442526 0l-17.354105-242.903579A75.290947 75.290947 0 0 1 646.736842 323.368421z"
                            fill="#FF6A6A" p-id="6135"></path>
                          <path
                            d="M646.736842 808.421053m-53.894737 0a53.894737 53.894737 0 1 0 107.789474 0 53.894737 53.894737 0 1 0-107.789474 0Z"
                            fill="#FF6A6A" p-id="6136"></path>
                        </svg></span>
                    </template>
                  </el-table-column>
                  <el-table-column prop="export_country_inter" label="Origin" width="120" show-overflow-tooltip
                    align="center">
                    <template slot-scope="scope">
                      <span class="table-content company_link">{{ scope.row.export_country_inter }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column prop="Amount" wi label="Amount" width="100" align="center">
                    <template slot-scope="scope">
                      <span class="table-content ">{{ scope.row.total_count | FilterNum }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column prop="Details" label="Details" align="center" width="90">
                    <template slot-scope="scope">
                      <svg t="1718681277467" class="icon" @click="gotoExportDetail(scope.row.data_id)"
                        viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="6986" width="32"
                        height="32">
                        <path
                          d="M867.2 995.7H159.8c-51.7 0-93.8-42.1-93.8-93.8V139.6c0-51.7 42.1-93.8 93.8-93.8h707.3c51.7 0 93.8 42.1 93.8 93.8v762.3c0.1 51.8-42 93.8-93.7 93.8zM159.8 85.8c-29.7 0-53.8 24.1-53.8 53.8v762.3c0 29.7 24.1 53.8 53.8 53.8h707.3c29.7 0 53.8-24.1 53.8-53.8V139.6c0-29.7-24.1-53.8-53.8-53.8H159.8z"
                          fill="#009EA7" p-id="6987"></path>
                        <path
                          d="M753.2 381.2H273.8c-11 0-20-9-20-20s9-20 20-20h479.3c11 0 20 9 20 20s-8.9 20-19.9 20zM521.9 540.8H273.8c-11 0-20-9-20-20s9-20 20-20h248.1c11 0 20 9 20 20s-8.9 20-20 20zM644.9 700.4H273.8c-11 0-20-9-20-20s9-20 20-20h371.1c11 0 20 9 20 20s-8.9 20-20 20z"
                          fill="#009EA7" p-id="6988"></path>
                      </svg>
                      <!-- <span class="table-content">{{ scope.row.Details }}</span> -->
                    </template>
                  </el-table-column>
                </el-table>
                <div style="text-align: center; padding-top: 20px" v-if="this.page.total>this.page.pageSize">
                  <span class="contclass show_All" @click="ShowAll()">Show All</span>
                </div>
              </div>

            </div>

          </div>

        </div>
        <!-- </div> -->



      </div>

    </div>
    <ReLogin :reLoginPop="reLoginVis"></ReLogin>
    <!--点击进出口信息详情 -->
    <el-dialog :visible.sync="imexportsDialog" :close-on-click-modal="true" custom-class="large_dialog"
      :append-to-body="true">
      <div class="large_detail_cont trade-details-modal" id="exportDetail">
        <!-- <div class="dialog_detail_title" style="margin-bottom: 0;">Trade Transaction Detail</div> -->
        <div class="ant-modal-body">
          <div class="trade-details-field">
            <table>

              <tbody>
                <tr style="height: 24px">
                  <th style="width: 15%">Date:</th>
                  <td style="width: 35%">
                    {{ value?.imp_year }}
                    <span v-if="value?.imp_month">-</span>
                    {{ value?.imp_month }}
                    <span v-if="value?.imp_day">-</span>
                    {{ value?.imp_day ? value?.imp_day : '15' }}
                  </td>
                  <th style="width: 15%">Master Bill Of Lading:</th>
                  <td style="width: 35%">{{ value?.master_bill_of_lading }}</td>
                </tr>
                <tr style="height: 24px" class="back">
                  <th style="width: 15%">Source:</th>
                  <td style="width: 35%">
                    {{ value?.channel_no }}_
                    <span v-if="value?.iesign == 'i'">IMPORT</span>
                    <span v-if="value?.iesign == 'e'">EXPORT</span>
                  </td>

                  <th style="width: 15%">Bill No.:</th>
                  <td style="width: 35%">
                    {{ value?.source_id }}
                    <span v-if="value?.source_id_type">({{ value?.source_id_type }})</span>

                  </td>
                </tr>
              </tbody>
            </table>

            <table>
              <thead>
                <tr>
                  <th colspan="4">Importer/Exporter</th>
                </tr>
              </thead>
              <tbody>
                <tr style="height: 24px">
                  <th style="width: 15%"></th>
                  <td style="width: 35%">Importer/Buyer</td>
                  <th style="width: 15%"></th>
                  <td style="width: 35%">Exporter/Seller</td>
                </tr>
                <tr style="height: 24px" class="back">
                  <th style="width: 15%">Name:</th>
                  <td style="width: 35%" @click="tradetodetail(value?.importer_aaaid, value?.importer_country)">
                    <span style="text-decoration: underline; color: rgb(18, 144, 201); cursor: pointer">{{
                      value?.importer_name }}</span>
                  </td>
                  <th style="width: 15%"></th>
                  <td style="width: 35%" @click="tradetodetail(value?.supplier_aaaid, value?.supplier_country)">
                    <span style="text-decoration: underline; color: rgb(18, 144, 201); cursor: pointer">{{
                      value?.supplier_name }}</span>
                  </td>
                </tr>
                <tr style="height: 24px">
                  <!-- <th style="width: 15%">Importer Name</th> -->
                  <th style="width: 15%">Address:</th>
                  <td style="width: 35%">{{ value?.importer_address }}</td>
                  <th style="width: 15%"></th>
                  <td style="width: 35%">{{ value?.supplier_address }}</td>
                  <!-- <td colspan="3">
                                        <div class="button-list-in-trade-detail">
                                            <span class="row-content supplier"  @click="tradetodetail(value?.importer_aaaid,value?.importer_country)" style="max-width: 50%;text-decoration: underline;color: #1290C9;cursor: pointer;">{{value?.importer_name}}</span>
                        
                                        </div>
                                    </td> -->
                  <!-- <th style="width: 15%">Importer Name</th> -->
                </tr>
                <tr style="height: 24px" class="back">
                  <th style="width: 15%">Country:</th>
                  <td style="width: 35%">
                    <img v-if="value?.importer_country" class="companyimg"
                      @click="gotocountry2(value?.importer_country)"
                      :src="require('../../../static' + getCountryImgUrl(value?.importer_country))" />
                    <span style="text-decoration: underline; color: rgb(18, 144, 201); cursor: pointer"
                      @click="gotocountry2(value?.importer_country)">{{ getCountry(value?.importer_country) }}</span>
                  </td>
                  <th style="width: 15%"></th>
                  <td style="width: 35%">
                    <img v-if="value?.supplier_country" class="companyimg"
                      @click="gotocountry2(value?.supplier_country)"
                      :src="require('../../../static' + getCountryImgUrl(value?.supplier_country))" />
                    <span style="text-decoration: underline; color: rgb(18, 144, 201); cursor: pointer"
                      @click="gotocountry2(value?.supplier_country)">{{ getCountry(value?.supplier_country) }}</span>
                  </td>
                </tr>
                <tr style="height: 24px">
                  <th style="width: 15%">Contact:</th>
                  <td style="width: 35%">{{ value?.importer_contact }}</td>
                  <th style="width: 15%"></th>
                  <td style="width: 35%">{{ value?.supplier_contact }}</td>
                </tr>
                <tr style="height: 24px" class="back">
                  <th style="width: 15%">Email:</th>
                  <td style="width: 35%">{{ value?.importer_email }}</td>
                  <th style="width: 15%"></th>
                  <td style="width: 35%">{{ value?.supplier_email }}</td>
                </tr>
              </tbody>
            </table>
            <!-- 货物信息 -->
            <table>
              <thead>
                <tr>
                  <th colspan="4">Cargo Info</th>
                </tr>
              </thead>
              <tbody>
                <tr style="height: 24px">
                  <th style="width: 15%">HS Code:</th>
                  <td style="width: 35%">{{ value?.hs_code }}</td>
                  <th style="width: 15%">HS Description:</th>
                  <td style="width: 35%;">
                    {{ value?.hs_code_description }}
                  </td>
                  <!-- <td style="width: 35%" v-html="value?.commodity_description"></td> -->

                </tr>
                <tr>
                  <th style="width: 15%" class="back">Product Description:</th>
                  <td colspan="3" class="back">
                    <div v-if="!isExpanded" @click="toggleExpanded" v-html="limitedDescription"></div>
                    <div v-else @click="toggleExpanded" v-html="value?.commodity_description"></div>
                    <span style="float: right;color: #55B419;cursor: pointer;text-decoration: underline;"
                      v-if="value?.commodity_description?.length > maxCharacters" @click="toggleExpanded">{{ isExpanded
                        ?
                        'Retract' : 'Expand All Content' }}</span>
                  </td>
                </tr>
                <tr style="height: 24px">
                  <th style="width: 15%">Weight:</th>
                  <td style="width: 35%">{{ value?.weight }}</td>
                  <th style="width: 15%">Weight Unit:</th>
                  <td style="width: 35%">{{ value?.weight_unit }}</td>
                </tr>
                <tr style="height: 24px" class="back">
                  <th style="width: 15%">Quantity:</th>
                  <td style="width: 35%">{{ value?.quantity }}</td>
                  <th style="width: 15%">Quantity Unit:</th>
                  <td style="width: 35%">{{ value?.quantity_unit }}</td>
                </tr>
                <tr style="height: 24px">
                  <th style="width: 15%">Amount:</th>
                  <td style="width: 35%" v-if="value?.fob_usd != null">{{ value?.fob_usd }}</td>
                  <td style="width: 35%" v-else>{{ value?.other_currency }}{{ value?.fob_oc }}</td>
                  <th style="width: 15%">Incoterms:</th>
                  <td style="width: 35%" v-if="value?.value_type == 'CIF' || value?.value_type == 'FOB'">{{
                    value?.value_type }}</td>
                  <td style="width: 35%" v-else></td>
                </tr>
              </tbody>
            </table>

            <table>
              <thead>
                <tr>
                  <th colspan="4">Shipping Info</th>
                </tr>
              </thead>
              <tbody>
                <tr style="height: 24px">
                  <th style="width: 15%">Vessel:</th>
                  <td style="width: 35%">{{ value?.vessel }}</td>
                  <th style="width: 15%">Voyage:</th>
                  <td style="width: 35%">{{ value?.voyage }}</td>
                </tr>
                <tr style="height: 24px" class="back">
                  <th style="width: 15%">Country of Origin:</th>
                  <td style="width: 35%">
                    <img v-if="value?.origin_country" class="companyimg"
                      :src="require('../../../static' + getCountryImgUrl(value?.origin_country))"
                      @click="gotocountry2(value?.origin_country)" />
                    <span style="text-decoration: underline; color: rgb(18, 144, 201); cursor: pointer"
                      @click="gotocountry2(value?.origin_country)">{{ getCountry(value?.origin_country) }}</span>
                  </td>

                  <th style="width: 15%">Address Of Origin:</th>
                  <td style="width: 35%">{{ value?.poe }}</td>
                </tr>

                <tr style="height: 24px">
                  <th style="width: 15%">Country Of Destnation:</th>
                  <td style="width: 35%">{{ value?.dest_country }}</td>
                  <th style="width: 15%">Address Of Destnation</th>
                  <td style="width: 35%">{{ value?.poa }}</td>
                </tr>
                <tr style="height: 24px" class="back">
                  <th style="width: 15%">Shipper:</th>
                  <td style="width: 35%">{{ value?.supplier_name }}</td>
                  <th style="width: 15%">Means Of Transport:</th>
                  <td style="width: 35%">{{ value?.trans_type }}</td>
                </tr>
                <tr style="height: 24px">
                  <th style="width: 15%">Consignee:</th>
                  <td style="width: 35%">{{ value?.importer_name }}</td>
                  <th style="width: 15%">Notifier:</th>
                  <td style="width: 35%">{{ value?.notify_party_name }}</td>
                </tr>


              </tbody>
            </table>

            <!-- 金额信息 -->
            <table>
              <thead>
                <tr>
                  <th colspan="4">Container</th>
                </tr>
              </thead>
              <tbody>
                <tr style="height: 24px">
                  <th style="width: 15%">Container ID:</th>
                  <td style="width: 35%; word-break: break-all">{{ value?.container_id }}</td>
                  <th style="width: 15%">Container Type:</th>
                  <td style="width: 35%; word-break: break-all">{{ value?.container_type }}</td>
                </tr>
                <tr style="height: 24px" class="back">
                  <th style="width: 15%">Container Size:</th>
                  <td style="width: 35%; word-break: break-all">{{ value?.container_size }}</td>
                  <th style="width: 15%">Container Desc Dode:</th>
                  <td style="width: 35%; word-break: break-all">{{ value?.container_desc_code }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

      </div>
      <div class="flex_center" style="justify-content: right;">

        <div class="btn_export flex_center" style="background-color: #55B419 !important;" @click="exportDetail">
          <img src="../../../../assets/img/search/export_ic1.png" />
          Export
        </div>
      </div>
    </el-dialog>
  </div>

  <!--srcviewscompanydetailsBackgroundSnapshotvuehtmlEnd-->
</template>
<script>
// interfaceCode
//importStart
import {
  getbusinessActivitiesDetails
} from "@/api/companyApi";
import rectcard from "../../../../components/rectCard/index.vue";
import content_text from "../../../../components/text_content/index.vue";
import gui_card from "../../../../components/gui_card/gui_card.vue";
import { mixins1 } from '@/mixins/index';
import ReLogin from "../../../../components/ReLogin.vue";
import { searchCompany, productSearch, searchTradeHscode, searchFilterConditions } from "@/api/searchApi.js";
//importEnd

export default {
  name: "",
  mixins: [mixins1],
  props: {},

  components: {
    gui_card,
    rectcard,
    content_text,

    ReLogin,
  },
  computed: {},
  created() {

  },
  mounted() {

    this.$emit("UpdatamarkTotalList", []);
    this.getCompanyList()
    this.getsearchFilterConditions()
    this.$store.dispatch("app/toggleMenu", true);
    this.$store.dispatch("app/setTools", {
      name: "saved",
      key: "isActive",
      value: true,
    });
  },
  data() {
    return {
      reLoginVis: false,
      key: 0,
      Related: false,
      isExpanded: false,
      datePickerTwo:'',
      parentTablelist: [],
      imexportsDialog: false,
      companyList: [],
      value: {},
      maxCharacters: 200,
      imexportsDialog: false,
      content: [],
      exportLoding: false,
      loading:true,
  page:{
    pageSize: 10,
      page: 1,
      total:0,
  },
      ImporttableData: [],
      countryOfDesList: [],
      countryOfDesvalue: '',
      countryOfOrgList: [],
      countryOfOrgvalue: '',

      hsCodeList: [],
      hsCodevalue: '',
      productList: [],

      productvalue: '',
      supplierNameList: [],
      supplierNamevalue: "",
      datePicker: [],
      datePickerOne:'',
      buyerNameList: [],
      buyerNamevalue: '',
      Improtradio: 0,
      


      // srcviewscompanydetailsBackgroundSnapshotvueDataEnd
    };
  },
  computed: {
    limitedDescription() {
      const maxCharacters = 200;
      if (this.value && this.value?.commodity_description) {
        if (this.value.commodity_description.length > maxCharacters) {
          return this.value?.commodity_description.substring(0, maxCharacters) + '...';
        } else {
          return this.value?.commodity_description;
        }
      }
      return '';
    },

    rows() {
      const rows = [];
      let row = [];
      for (const item of this.content) {
        if (item.field) {
          row.push(item);
          if (row.length === 2) {
            rows.push(row);
            row = [];
          }
        } else {
          rows.push([item]);
        }
      }
      if (row.length > 0) {
        rows.push(row);
      }
      return rows;
    },
  },
  methods: {
    datepcikerChange(){
      if(this.datePickerOne &&this.datePickerTwo){
      let max=( Math.min(Number(this.datePickerOne==''?0:this.datePickerOne),Number(this.datePickerTwo==''?0:this.datePickerTwo)))
      let min=( Math.max(Number(this.datePickerOne==''?0:this.datePickerOne),Number(this.datePickerTwo==''?0:this.datePickerTwo)))
    this.datePickerOne=max==0?'':max.toString()
    this.datePickerTwo=min==0?'':min.toString()

      }
      this.getCompanyList()
      this.getsearchFilterConditions()
    },

    ShowAll() {
     
     if(this.page.total>this.page.pageSize){
     this.page.pageSize=this.page.pageSize+10
     this.getCompanyList();

     }
     
   },
    selectChange(){
      this.getCompanyList()
      this.getsearchFilterConditions()
    },
    Improtradiochange() {
      if (this.Improtradio == 2) {
        this.countryOfOrgvalue = ''
        this.supplierNamevalue = ''
      }
      if (this.Improtradio == 1) {
        this.countryOfDesvalue = ''
        this.buyerNamevalue = ''
      }
      if (this.Improtradio == 0) {
        this.buyerNamevalue = ''
        this.supplierNamevalue = ''
      }
    },
    gotoExportDetail(row) {
      this.imexportsDialog = true;

      getbusinessActivitiesDetails({ id: row }).then(res => {
        if (res && res.code) {
          this.value = res.data;

          this.value.container_size = this.value?.container_size == undefined ? '' : this.value?.container_size.split(',')[0];
          this.value.container_type = this.value?.container_type == undefined ? '' : this.value?.container_type.split(',')[0];
          this.value.container_desc_code = this.value?.container_desc_code == undefined ? '' : this.value?.container_desc_code.split(',')[0];
        } else {
          this.value = {};
        }
      });
    },
    getCompanyList() {
      this.loading=true
      searchTradeHscode({
        keyword: this.$route.query.id3a,
          hscode: this.hsCodevalue,
          import_aaaid	: this.buyerNamevalue,
          export_aaaid: this.supplierNamevalue,
          import_country_code: this.countryOfDesvalue,
          export_country_code: this.countryOfOrgvalue,
        startTime:  this.datePickerOne,
        endTime: this.datePickerTwo,
        page: this.page.page,
        pageSize: this.page.pageSize

      }).then(res => {
        this.loading=false
        if (res.code == 200) {
          this.ImporttableData = res.data.rows
          this.page.total=res.data.total
        } else {
          this.ImporttableData = []
          this.page.total=0
        }

      })
    },
    getsearchFilterConditions() {
      searchFilterConditions(
        {
          keyword: this.$route.query.id3a,
          hscode: this.hsCodevalue,
          import_aaaid	: this.buyerNamevalue,
          export_aaaid: this.supplierNamevalue,
          import_country_code: this.countryOfDesvalue,
          export_country_code: this.countryOfOrgvalue,
          startTime:  this.datePickerOne,
          endTime: this.datePickerTwo,



        }
      ).then(res => {
        if (res.code == 200) {
          this.buyerNameList = res.data.buyerNameList
          this.countryOfDesList = res.data.countryOfDesList
          this.countryOfOrgList = res.data.countryOfOrgList
          this.hsCodeList = res.data.hsCodeList
          this.productList = res.data.hsCodeList
          this.supplierNameList = res.data.supplierNameList
        }
      })
    },
    scrollToTop() {
      if (this.parentTablelist.length > 0) {
        // this.Related = true;
      } else {
      }

      this.Related = true

    },
    exportDetail() {

      const element = document.getElementById('exportDetail');
      const options = {
        filename: 'exportDetail.pdf',
        image: { type: 'jpeg', quality: 1 },
        // html2canvas: { scale:1 },
        jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
      };
      html2pdf().from(element).set(options).save();
      // html2pdf().from(element).save();
    },
    toggleExpanded() {
      this.isExpanded = !this.isExpanded;
    },

    // 获取数据

    default() { },
    // srcviewscompanydetailsBackgroundSnapshotvueMethodEnd
  },

};
</script>
<style scoped>
.uPdtqFl {
  padding-right: 2.857142857142857rem;
  padding-top: 2.142857142857143rem;
  padding-left: 2.857142857142857rem;
  padding-bottom: 2.142857142857143rem;
  box-shadow: 0rem 0.14285714285714285rem 1rem 0rem rgba(0, 0, 0, 0.12);
  background-color: #ffffff;
  border-top-left-radius: 0.2857142857142857rem;
  border-bottom-left-radius: 0.2857142857142857rem;
  border-top-right-radius: 0.2857142857142857rem;
  border-bottom-right-radius: 0.2857142857142857rem;
}

.importDetail-head {
  display: flex;
}

.flex_center_between_box {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
}

.contclass {
  font-size: 1.4285714285714286rem;
  color: #238ACC;
  font-weight: normal;
  font-style: normal;
  cursor: pointer;
}

.ViewMore {
  line-height: 1.8571428571428572rem;
  text-align: left;
  font-style: normal;
  text-decoration-line: underline;
  margin-left: 2.142857142857143rem;
}

.uc1096 {
  height: 100%;
}

.head-titel {
  font-family: 'DMSans Bold';
  font-weight: 400;
  font-size: 2.142857142857143rem;
  display: flex;
  align-items: center;

}

.operations-head {
  height: 2.857142857142857rem;
  font-family: 'DMSans Bold';
  margin-bottom: 2.142857142857143rem;
}

.title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title-left {
  display: flex;
  font-size: 1.1428571428571428rem;
  font-family: Arial-BoldMT, Arial;
  font-weight: normal;
  color: #022955;
  padding-left: 1.4285714285714286rem;
  background-color: #f2f5f9;
}

.footer {
  padding-left: 0rem;
  overflow: hidden;
}

.ecahrts-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 0.07142857142857142rem solid #cccccc;
}

.title-left {
  display: flex;
  align-items: center;
  padding-bottom: 0.9285714285714286rem;
  padding-top: 0.9285714285714286rem;
}

.td {
  margin: 1.0714285714285714rem 0 0 1.7857142857142858rem;
}

.relate-left {
  height: 4.142857142857143rem;
  font-size: 1.4285714285714286rem;
  color: #1a2332;
  line-height: 4.142857142857143rem;
  text-align: left;
  font-family: 'DMSans Bold';
}

.relate-Financial {
  font-size: 1.4285714285714286rem;
  color: #1a2332;
  font-size: Arial;
}

.computer_right_cont_box {
  margin-top: 1.4285714285714286rem;
}

.right_cont_box {
  padding: 2.142857142857143rem 2.857142857142857rem;
  height: 100%;
  background: #FFFFFF;
  box-shadow: 0em 0.14285714285714285em 1em 0em rgba(0, 0, 0, 0.12);
  border-radius: 4px;
}


.tablewieth {
  width: 70%;
}

.aaa {
  font-size: 0.7142857142857143rem;
  color: #fa6666;
  position: absolute;
  left: 2.142857142857143rem;
  top: 14.285714285714286rem;
}

.bbb {
  font-size: 0.7142857142857143rem;
  color: #fa755c;
  position: absolute;
  left: 2.5rem;
  top: 6.071428571428571rem;
}

.ccc {
  font-size: 0.7142857142857143rem;
  color: #fc9249;
  position: absolute;
  left: 7.5rem;
  top: 0.5714285714285714rem;
}

.ddd {
  font-size: 0.7142857142857143rem;
  color: #ffd81a;
  position: absolute;
  left: 23.214285714285715rem;
  top: 0.5714285714285714rem;
}

.fff {
  font-size: 0.7142857142857143rem;
  color: #aec918;
  position: absolute;
  width: 3.642857142857143rem !important;
  height: 2.142857142857143rem;
  top: 6.071428571428571rem;
  left: 30rem;
}

.ggg {
  font-size: 0.7142857142857143rem;
  color: #63b819;
  position: absolute;
  left: 28.928571428571427rem;

  top: 14.285714285714286rem;
}

.hhh {
  font-size: 1.4285714285714286rem;
  color: #1a2332;
  position: absolute;
  left: 13.428571428571429rem;
  top: 14.285714285714286rem;
}

.echarts {
  width: 30.714285714285715rem;
  height: 17.857142857142858rem;
  padding-left: 3.2857142857142856rem;
}

.table_box {
  position: relative;
}

.echarts-content {
  margin-top: 2.857142857142857rem;
  padding: 0 4.285714285714286rem;
}

.echarts-item {
  display: flex;
  justify-content: flex-start;
  font-size: 1.4285714285714286rem;
  color: #1a2332;
  line-height: 1.6428571428571428rem;
  margin-bottom: 2.142857142857143rem;
}

.echarts-item-left {
  display: inline-block;
  width: 15.714285714285714rem;
  margin-right: 1.4285714285714286rem;
  font-family: 'DMSans Bold';
}

.ecahrtstutwo {
  position: relative;
  left: 1.4285714285714286rem;
}

.uUIRzUi {
  padding: 2.142857142857143rem 2.857142857142857rem;
}

.left_box {
  width: 91%;

}

.right_box {
  width: 40%;
  background-color: #fff;
}

.operations-box-bottom /deep/ .el-table .cell {
  font-size: 1.28571429rem;
  color: #1a2332;
  line-height: 1.6428571428571428rem;
  padding-left: 0.7142857142857143rem !important;
}

/* 贸易关单 */
.table_oper1 {
  display: inline-block;
  width: 26px;
  height: 26px;
  line-height: 27px;
}

.ant-modal-body {
  padding: 24px;
  font-size: 1rem;
  color: #1A2332;
  line-height: 1.5;
  word-wrap: break-word;
}

.trade-details-field {
  border-right: 1px solid #ddd;
  border-left: 1px solid #ddd;
}

.trade-details-modal table {
  width: 100%;
  margin-bottom: 18px;
  font-size: 1rem;
}

.trade-details-modal table,
.trade-details-modal td,
.trade-details-modal th {
  /* border: 1px solid #ddd; */
  padding: 0.55rem;
  border-collapse: collapse;
  word-break: keep-all;
  text-align: right;
}

.trade-details-modal table thead {
  background: #55B419;
  color: #fff;
}

.trade-details-modal table thead tr>th {
  /* color: #000; */
  font-weight: 700;
  padding-left: 12px;
  text-align: left;
}

.trade-details-modal th {
  /* border: 1px solid #ddd; */
  padding: 0.55rem;
}

.button-list-in-trade-detail {
  display: inline-block;
}

.button-list-in-trade-detail .row-content {
  display: inline-block;
  padding-right: 40px;
  vertical-align: top;
}

.button-list-in-trade-detail .link-button {
  display: inline-block;
  padding: 0 9px;
  color: #177dff;
  cursor: pointer;
  border-radius: 2px;
  transition: all 0.25s;
}

.trade-details-modal table tbody tr>td {
  color: rgba(0, 0, 0, 0.65);
  padding-left: 12px;
  text-align: left;
  line-height: 25px;
  /* word-break: break-all; */
}

.trade-details-modal td {
  /* border: 1px solid #ddd; */
  padding: 0.55rem;
}

.button-list-in-trade-detail .ant-divider-vertical {
  margin: 0 3px;
}

.ant-divider,
.ant-divider-vertical {
  position: relative;
  top: -0.06em;
  display: inline-block;
  width: 1px;
  height: 0.9em;
  margin: 0 8px;
  vertical-align: middle;
}

.ant-divider {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum';
  background: #e8e8e8;
}

.line_11 {
  color: rgb(18, 144, 201);
  text-decoration: underline;
  cursor: pointer;
}

.back {
  background-color: #f1f3f4;
}

.trade-details-modal tr {
  border: 1px solid #ddd;
  border-right: none;
  border-left: none;
}

.companyimg {
  float: left;
  width: 36px;
  height: 26px;
  flex: none;
  margin-right: 4px;
  box-shadow: 0px 1px 2px 0px rgb(0 0 0 / 20%);
}

.left_menu {

  background-color: #fff;
  max-height: 95%;
  overflow-y: auto;
  height: auto;
  box-shadow: 0px 2px 14px 0px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  padding: 2.142857142857143rem 1.42857142857143rem;

}

.hidemenu {
  display: block;
  width: 0em;
  padding-left: 0em;
  padding-top: 0em;
  padding-bottom: 0em;
  padding-right: 0em;
  box-sizing: border-box;
  overflow-y: hidden;
  overflow-x: hidden;
}

.udb507 {
  width: calc(100% - 25em);
  display: flex;
  height: 100%;
  box-sizing: border-box;
  flex-direction: column;
  padding-left: 0em;
  margin-left: 0.7142857142857143em;
}

.udb507two {
  width: 100% !important;
}

.left_menu_context-title {
  font-size: 1.28571429rem;
  color: #1A2332;
  font-family: 'DMSans Bold';
  margin-bottom: 1.4285714285714286rem;
}

.left_menu_context {
  padding-bottom: 1.4285714285714286rem;
  margin-bottom: 1.4285714285714286rem;
  border-bottom: 1px solid #CCCCCC;
}

.el-range-editor.el-input__inner {
  padding: 0px !important;
}

.left_menu_context /deep/ .el-input__inner {
  height: 36px !important;
}

/deep/ .el-radio {
  justify-content: flex-start;
  font-size: 1.1428575rem !important;
}

/deep/ .el-radio__input.is-checked+.el-radio__label {
  color: #009EA7;
  font-size: 1.1428575rem !important;
}

/deep/ .el-radio__label {
  font-size: 1.1428575rem !important;
  color: #1a2332;
}

/deep/ .el-radio__input.is-checked .el-radio__inner {
  border-color: #009EA7;
  background: #fff;
}

/deep/ .el-radio__input.is-checked .el-radio__inner::after {
  background: #009EA7;
}
.datePickerOne /deep/.el-input__inner{
border: none !important;
}
.datePickerOne /deep/ .el-input__prefix{
left: -22px !important;
color: #1A2332 !important;
}
.SactionIcon {
  position: relative;
  top: 0.3rem;
  left: 1rem;
}
.dadePickerBorder{
  border-radius: 4px;
border: 1px solid #CCCCCC;
}
</style>
